<template>
	<div class="page">
		<div class="header">
			<div class="logo">
				<img src="../../assets/logo1.png" alt="">
			</div>
			<div class="header-right">
				<div class="search-box">
					<div class="search">
						<input type="text" placeholder="请输入关键词，或截图搜索" v-model="search" />
					</div>

					<div class="search-camera">
						<el-upload action="/api/newapi/HomePage/searchImgUpload"
						:show-file-list="false"
							:on-success="handleRemove">
							<img src="@/assets/camera.png" title="以图搜图"/>
						</el-upload>
					</div>
				</div>
				<div class="search-btn" @click="searchDo">
					<img src="@/assets/search.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

				keysWord: "",

				pic: "",
				cate: "",
				headerActive: 1,
				search: "", //搜索
				topShow: false, //header文件上传
				value: "",
			};
		},

		created() {
			this.pic = localStorage.getItem("head_pic");
		},

		methods: {

			//
			goOpenArea(id) {
				this.$router.push({
					path: `/openArea/${id}`,
				});
				this.$router.go(0);
			},
			//退出登录
			loginOut() {
				loginOut({}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});

						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.go(0);
					}
				});
			},

			//搜索
			searchDo() {
				if (this.$route.path == "/search") {
					this.$route.query.keysWord = this.keysWord;

					this.$router.push({
						url: ''
					})
				}

				this.$router.push({
					name: 'Search',
					query: {
						keysWord: this.search,
						imgs:0
					},
				});
			},
			go(url) {
				this.$router.push(url);
			},
			handleRemove() {
					if (this.$route.name == "Search") {
						this.$route.query.keysWord = this.keysWord;
						this.$router.push({
							url: ''
						})
					}
			        this.$router.push({
						name:'Search',
						query: {
							keysWord: '',
							imgs:1
						},
					});
			},
		},
	};
</script>

<style lang="less" scoped>
	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 96%;
		background-color: #08243C;
		padding: 15px 2%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 999;

		.logo {

			// margin-left: 9px;
			img {
				width: 60px;
				height: 20px;
			}
		}

		.header-right {
			display: flex;
			align-items: center;
			position: relative;

			.search-box {
				display: flex;
				align-items: center;
				//   width: 494px;
				height: 25px;
				background: #ffffff;
				border-radius: 3px 0px 0px 3px;
				padding-right: 9px;

				.search {
					input {
						background: none;
						outline: none;
						border: none;
						padding: 5px 9px;
						width: 200px;
						font-size: 12px;
						transform: scale(0.95)
					}
				}

				.search-camera {
					img {
						width: 12px;
						height: 12px;
						display: block;
					}
				}
			}

			.search-btn {
				width: 25px;
				height: 25px;
				background: #e66617;
				border-radius: 0px 3px 3px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				img {
					width: 12px;
					height: 12px;
				}
			}
		}

	}
</style>
