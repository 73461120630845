import Vue from 'vue';

import {
  Button,
  Input,
  Carousel,
  CarouselItem,
  Divider,
  Upload,
  Select,
  Backtop,
  Checkbox,
  Dialog,
  Pagination,
  CheckboxButton,
  CheckboxGroup,
  Icon,
  Avatar,
  Table,
  TableColumn,
  DatePicker,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Message,
  Option,
  OptionGroup,
  Radio,
  RadioGroup,
  RadioButton,
  MessageBox,
  Tooltip,
  Empty,
  Progress



} from 'element-ui'

Vue.use(Button)
Vue.use(Input)

Vue.use(Carousel)

Vue.use(CarouselItem)

Vue.use(Divider)
Vue.use(Upload)

Vue.use(Select)

Vue.use(Backtop)

Vue.use(Checkbox)

Vue.use(Dialog)

Vue.use(Pagination)

Vue.use(Dialog)

Vue.use(Icon)

Vue.use(Avatar)

Vue.use(Table)

Vue.use(TableColumn,)
Vue.use(DatePicker)

Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);

Vue.use(Menu)

Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Breadcrumb)

Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)

Vue.use(Option)

Vue.use(OptionGroup)

Vue.use(Radio)
Vue.use(RadioGroup)

Vue.use(RadioButton)

Vue.use(Tooltip)

Vue.prototype.$alert = MessageBox.alert;

Vue.prototype.$message = Message;

Vue.component(Message.name, Message)
Vue.prototype.$confirm = MessageBox.confirm;

Vue.prototype.$message = Message

Vue.use(Empty)
Vue.use(Progress)
