//引入接口中的域名
// import imgUrl from "../../components/common/footer.vue";
function imageUrl(url){
  let str = RegExp('http');
  let newUrl;
  //通过三元运算符进行判断该图片是否含有http域名，没有就拼接上去
  str.test(url) ? newUrl = url : newUrl = this.imgUrl + url;
  newUrl = newUrl.replace(this.imgUrl+'/', this.imgUrl,newUrl);
  return newUrl
}
module.exports = imageUrl;