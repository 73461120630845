<template>
  <div>
    <div class="page">
      <top />
      <div class="banner">
        <el-carousel
          indicator-position="none"
          :interval="5000"
          arrow="never"
          style="width: 100vw; height: 175rpx"
        >
          <el-carousel-item v-for="(item, index) in banner" :key="index">
            <img
              :src="imageUrl(item.litpic)"
              alt=""
              style="width: 100vw; height: 175rpx"
            />
          </el-carousel-item>
        </el-carousel>
        <div class="header-right">
          <div class="search-box">
            <div class="choose" @click="isChoose = !isChoose">
              <div>{{ choose1 }}</div>
              <img
                src="../../assets/down.png"
                style="width: 10px; height: 4px; margin-left: 4px"
              />
            </div>
            <div class="search">
              <input
                type="text"
                placeholder="请输入关键词，或截图搜索"
                v-model="search"
              />
            </div>
            <div class="search-camera">
              <el-upload
                action="/api/newapi/HomePage/searchImgUpload"
                :show-file-list="false"
                :on-success="handleRemove"
              >
                <img src="@/assets/camera.png" title="以图搜图" />
              </el-upload>
            </div>
          </div>
          <div class="search-btn" @click="searchDo">
            <img src="@/assets/search.png" />
          </div>
        </div>
        <div class="dropdown" v-show="isChoose == true">
          <div
            class=""
            v-for="(v, k) in cate"
            :key="k"
            @click="choose(v.id, v.typename)"
          >
            {{ v.typename }}
          </div>
        </div>
		
		<div class="hot-list">
		  <div class="hot-item" v-for="(item, index) in tags_list" :key="index" @click="gotags(item.url,item.title)">{{item.title}}</div>
		</div>
      </div>
      <div class="Fitting-box">
        <div class="Fitting-content">
          <div class="Fitting-warp">
            <img
              :src="imageUrl(imgA)"
              style="width: 190px; height: 100px"
              alt=""
            />
          </div>
          <div class="Fitting-warp">
            <img
              :src="imageUrl(imgB)"
              style="width: 155px; height: 100px"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="resources">
        <div class="title">海量设计资源，超高优惠</div>
        <div class="title-text">
          目前在线
          <span style="color: #e66617">{{ onlineData.onlineImgNum }}</span>
          张图片，<span style="color: #e66617">{{
            onlineData.designersNum
          }}</span
          >设计师，昨日新增
          <span style="color: #e66617">{{
            onlineData.yesterdayArchivesNum
          }}</span>
          张
        </div>

        <div class="contribution" @click="go('Vip')">
          <img src="@/assets/contribution.png" alt="" />
          <span>我要优惠！</span>
        </div>
      </div>
      <!-- 热门精品 -->
      <div class="special">
        <div class="df">
          <div class="special-warp">
            <div>热门精品</div>
            <div>Popular boutique</div>
          </div>
        </div>

        <div class="special-content">
          <div
            class="special-item"
            v-for="(item, index) in recommendType"
            :key="index"
            @click="searchDo2(item.typename)"
          >
            <img :src="imageUrl(item.litpic)" alt="" />
            <div class="special-text">{{ item.typename }}</div>
          </div>
        </div>
        <div class="jp" @click="goBoutique">查看更多精品</div>
      </div>
      <div class="special">
        <div class="special-warp">
          <div>新锐设计师</div>
          <div>Cutting edge designers</div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div class="designer-warp1">
			  <div
			    :class="arrange == true ? 'colorE66617' : 'color333333'"
			    @click="arrangement('two')"
			  >
			    按上传数量排列
			  </div>
            <div
              :class="arrange == false ? 'colorE66617' : 'color333333'"
              @click="arrangement('one')"
            >
              按销量排列
            </div>
          </div>
          <div style="font-size: 14px" @click="go('AuthorAll')">全部</div>
        </div>

        <div class="special-content">
          <div
            class="special-item1"
            v-for="(item, index) in designers"
            :key="index"
            v-show="index < 6"
            @click="goAuthor(item.users_id)"
          >
            <div>
              <img :src="imageUrl(item.head_pic)" alt="" />
            </div>
            <div class="name">{{ item.nickname }}</div>
            <div class="more">查看更多</div>
          </div>
        </div>
      </div>
	  <div class="link">
		  <div class="df">
			<div class="link-warp">
			  <div>友情链接</div>
			  <div>Friendship link</div>
			</div>
		  </div>
		  <div class="link-content">
			  <div class="link-item" v-for="(item, index) in link_list" :key="index" @click="gourl(item.url)">
				  <img :src="imageUrl(item.litpic)" alt="" />
			  </div>
		  </div>
	  </div>
      <info />
      <div style="width: 100%; height: 60px; background-color: #f6f6f6"></div>
      <foot />
      <el-dialog :visible.sync="adShow" width="75%" center>
        <a :href="`http://` + ahref" @click="adShow = false">
          <img style="width: 100%" :src="adimg" alt="" />
        </a>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import info from "../../components/common/qrCode.vue";

import {
  IndexData,
  loginOut,
  search,
  designer,
  webConfigAd,
  topCate,
} from "../../request/api";

export default {
  components: {
    top,
    foot,
    info,
  },
  created() {
    this.getIndexData();
    this.getDesigner();
    this.head_pic = localStorage.getItem("head_pic");
    webConfigAd().then((res) => {
      if (res.data.ad_mode.value == "2") {
        this.adShow = true;
        this.adimg = res.data.ad_content.value;
        this.ahref = res.data.ad_url.value;
        this.$forceUpdate();
      }
    });
    topCate().then((res) => {
      this.cate = res.data;
    });
  },

  data() {
    return {
      swipeBoxSpace: {
        propId: "swipeBoxSpace",
        height: "280px",
        width: "1280px",
        slidesPerView: 5,
        spaceBetween: 45,
      },
      banner: "", //轮播图
      topCate: "", //顶部分类
      imgA: "",
      imgB: "",
      onlineData: "", // 海量设计资源
      recommendType: "", //专题推荐
      designers: [], //新锐设计师
      designersShow: false,
      webQq: "", //客服qq
      head_pic: "", //用户头像

      type: "",
      keysWord: "",

      input: "",
      headerActive: 1,
      search: "", //搜索

      qqShow: false,
      topShow: false, //header文件上传

      bannerSearch: false,
      arrange: true,
      choose1: "请选择",
      isChoose: false,
      adimg: "",
      adShow: false,
      ahref: "",
      cate: "",
	  link_list:[],
	  tags_list:[]
    };
  },
  methods: {
    //获取首页数据
    getIndexData() {
      IndexData({}).then((res) => {
        console.log(res);

        this.banner = res.data.rotation;
        this.topCate = res.data.topCate;
        this.imgA = res.data.imgA.value;
        this.imgB = res.data.imgB.value;
        this.onlineData = res.data.onlineData;
        this.recommendType = res.data.recommendType;
        this.designersShow = true;
        this.webQq = res.data.webQq;
		this.link_list = res.data.link_list;
		this.tags_list = res.data.tags_list;
      });
    },

    //公开区跳转
    goOpenArea(id) {
      console.log(id);
      this.$router.push({
        path: `/openArea/${id}`,
      });
    },

    searchDo() {
      this.$router.push({
        name: "Search",
        query: {
          type: this.type,
          keysWord: this.search,
          imgs: 0,
        },
      });
    },

    searchDo1() {
      this.$router.push({
        name: "Search",
        query: {
          keysWord: this.search,
          imgs: 0,
        },
      });
    },
    searchDo2(name) {
      this.$router.push({
        name: "Search",
        query: {
          keysWord: name,
          imgs: 0,
        },
      });
    },
    goAuthor(author_id) {
      this.$router.push({
        name: "Author",
        query: {
          author_id: author_id,
        },
      });
    },
    goBoutique() {
      this.$router.push("/all");
    },
    loginOut() {
      loginOut({}).then((res) => {
        console.log(res);

        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          localStorage.removeItem("head_pic");
          localStorage.removeItem("nickname");
          this.$router.go(0);
        }
      });
    },
    go(url) {
      this.$router.push({
        name: url,
      });
    },
	gourl(url){
		if(url){
			window.open(url,'_blank');
		}
	},
	gotags(url,title){
		if(url){
			window.open(url,'_blank');
		}else{
			this.$router.push({
			  path: "/search",
			  query: {
			    keysWord: title,
			    imgs: 0,
			  },
			});
		}
	},
    arrangement(num) {
      this.arrange = !this.arrange;
      this.getDesigner();
    },
    //新锐设计师
    getDesigner() {
      designer({
        order_field: this.arrange == false ? "o_count" : "a_count",
      }).then((res) => {
        console.log(res);

        this.designers = res.data;
      });
    },
    choose(id,name) {
      this.type = id;
      this.choose1 = name;

      this.isChoose = false;
    },
    handleRemove() {
      if (this.$route.name == "Search") {
        this.$route.query.keysWord = this.keysWord;
        this.$router.push({
          url: "",
        });
      }
      this.$router.push({
        name: "Search",
        query: {
          keysWord: "",
          imgs: 1,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f6f6f6;
  .Fitting-box {
    width: 92%;
    padding: 0px 2% 15px 2%;

    .Fitting-content {
      width: 100%;
      display: flex;

      .Fitting-warp {
        cursor: pointer;

        img {
          display: block;
        }
      }
    }

    .Fitting-content .Fitting-warp:last-child {
      margin-left: 12px;
    }
  }
  .banner {
    width: 100%;
    height: 175px;
    margin-top: 55px;
    position: relative;
    .header-right {
      display: flex;
      align-items: center;
      position: absolute;
      top: 65px;
      left: 7%;
      .search-box {
        display: flex;
        align-items: center;
        z-index: 99;
        //   width: 494px;
        height: 30px;
        background: #ffffff;
        border-radius: 3px 0px 0px 3px;
        padding-right: 9px;
        .choose {
          display: flex;
          align-items: center;
          border-right: 1px rgba(146, 146, 146, 0.5) solid;
          font-size: 14px;
          transform: scale(0.8);
          padding-right: 4px;
        }
        .search {
          input {
            background: none;
            outline: none;
            border: none;
            padding: 5px 9px 5px 0px;
            width: 200px;
            font-size: 14px;
            transform: scale(0.95);
          }
        }

        .search-camera {
          img {
            width: 12px;
            height: 12px;
            display: block;
          }
        }
      }

      .search-btn {
        width: 25px;
        height: 25px;
        background: #e66617;
        border-radius: 0px 3px 3px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 99;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .dropdown {
      width: 80px;
      height: 92px;
      background-color: #ffffff;
      z-index: 999;
      position: absolute;
      top: 100px;
      left: 7%;
      border-radius: 2px;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      div {
        border-bottom: 1px #afafaf solid;
      }
    }
  }

  .resources {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      // line-height: 130px;
      text-align: center;
      height: 49px;
    }

    .title::after {
      content: "";
      width: 208px;
      height: 1px;
      background: linear-gradient(-90deg, #ffffff, #08243c, #ffffff);
      display: block;
      margin-top: 18px;
    }

    .title-text {
      padding: 7px 0 17px 0;
      font-size: 12px;
      font-weight: 400;
      color: #000000;
    }

    .contribution {
      width: 105px;
      height: 31px;
      background: #08243c;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;

      img {
        display: block;
        width: 15px;
        height: 13px;
        margin-right: 13px;
      }
    }
  }

  .special {
    width: 96%;
    margin: 0 auto;
    margin-top: 20px;
    .special-warp {
      display: flex;
      align-items: center;
    }

    .special-warp div:first-child {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }

    .special-warp div:first-child::after {
      content: "/";
      font-size: 12px;
      font-weight: 400;
      color: #afafaf;
      padding: 0 8px;
    }

    .special-warp div:last-child {
      font-size: 12px;

      font-weight: 400;
      color: #afafaf;
    }

    .special-content {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .special-item {
        margin-bottom: 10px;
        width: 48%;
        height: 185px;
        background: #ffffff;
        border-radius: 3px;
        cursor: pointer;

        img {
          width: 100%;
          height: 154px;
		  object-fit: cover;
          display: block;
        }

        .special-text {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          text-align: center;
          line-height: 31px;
        }
      }
      .special-item1 {
        margin-bottom: 10px;
        width: 48%;
        height: 195px;
        background: #ffffff;
        border-radius: 3px;
        cursor: pointer;
        text-align: center;
        img {
          border-radius: 50%;
          width: 74px;
          height: 74px;
          margin-top: 15px;
        }
        .name {
          font-size: 12px;
          margin-top: 20px;
          color: #08243c;
        }
        .more {
          margin: 0 auto;
          width: 60px;
          border: 1px solid #d2d7dc;
          border-radius: 10px;
          padding: 5px 15px;
          font-size: 12px;
          margin-top: 30px;
          color: #e66617;
        }
      }
    }
  }
  .designer-warp1 {
    display: flex;
    align-items: center;
  }

  .designer-warp1 div:first-child {
    font-size: 14px;
    font-weight: 400;
    // color: #E66617;
    line-height: 24px;
  }

  .designer-warp1 div:first-child::after {
    content: "|";
    font-size: 11px;
    font-weight: 400;
    color: #888888;
    padding: 0 12px;
  }

  .designer-warp1 div:last-child {
    font-size: 14px;

    font-weight: 400;
    // color: #333333;
    line-height: 16px;
  }
  .colorE66617 {
    color: #e66617;
  }
  .color333333 {
    color: #333333;
  }
  
  .link {
    width: 96%;
    margin: 0 auto;
    margin-top: 20px;
    .link-warp {
      display: flex;
      align-items: center;
    }
  
    .link-warp div:first-child {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
  
    .link-warp div:first-child::after {
      content: "/";
      font-size: 12px;
      font-weight: 400;
      color: #afafaf;
      padding: 0 8px;
    }
  
    .link-warp div:last-child {
      font-size: 12px;
  
      font-weight: 400;
      color: #afafaf;
    }
  
    .link-content {
      padding-top: 24px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
	  justify-content: space-between;
      .link-item {
        margin-bottom: 10px;
        width: 49%;
	    overflow: hidden;
	    border: 1px solid #e6e6e5;
	    text-align: center;
        cursor: pointer;
		box-sizing: border-box;
  
        img {
          vertical-align: middle;
		  height: 58px;
        }
	 }
	}
	}
}
.df {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jp {
  color: #000000;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #000000;
  width: fit-content;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  border-radius: 40px;
  text-align: center;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.hot-list{
	position: absolute;
	z-index: 99;
	top: 100px;
	left: 7%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	.hot-item{
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 0 10px;
		height: 20px;
		background: rgba(0,0,0,.3);
		font-size: 12px;
		color: #fff;
		line-height: 20px;
		border-radius: 20px;
		cursor: pointer;
	}
}

</style>
