<template>
	<div class="footer-box">
		<div class="footer">
			<div @click="go('Index')">
				<img src="../../assets/shouye.png" style="width: 19px; height: 17px" />
			</div>
			<div style="display: flex; align-items: center" class="footer-mid" @click="isShow = !isShow">
				<div>浏览图库</div>
				<div style="margin-left: 6px">
					<img src="../../assets/chouti.png" style="width: 10px; height: 7px" />
				</div>
			</div>
			<div style="display: flex; align-items: center" @click="Recruit">
				<div style="margin-left: 6px">行业资讯</div>
			</div>
			<div style="display: flex; align-items: center" v-if="head_pic" @click="go('My')">
				<div style="margin-right: 6px">我的</div>
				<div>
					<img :src="imageUrl(head_pic)" style="width: 29px; height: 29px; border-radius: 50%" />
				</div>
			</div>
			<div class="login" @click="go('Login')" v-else>登录</div>
		</div>
		<div class="droptop" v-show="isShow == true">
			<div v-for="(item, index) in cate" :key="index" @click="goOpenArea(item.id)">
				{{ item.typename }}
			</div>
		</div>
	</div>
</template>
<script>
	import {
		loginOut,
		searchImgUpload,
		topCate
	} from "../../request/api";
	export default {
		data() {
			return {
				webAttr: "",
				webCompanyAddress: "",
				webCopyright: "",
				webEmail: "",
				webQq: "",
				webRecordnum: "",
				webSiteTime: "",
				webintroduction: "",
				logo: "",
				isLogin: true,
				head_pic: "", //用户头像
				isShow: false,
				cate: "",
			};
		},
		created() {
			this.head_pic = localStorage.getItem("head_pic");
			topCate().then((res) => {
				this.cate = res.data;
			});
		},
		methods: {
			go(url) {
				console.log(url)
				this.$router.push({
					name: url,
				});
			},
			Recruit() {
				this.$router.push({
					path: `/Recruit`,
				});
			},
			goOpenArea(num) {
				let pathName = this.$route.path;
				this.$router.push({
					name: "List",
					query: {
						keysWord: num,
					},
				});
				if (pathName == "/list") {
					this.$router.go(0);
				}
				this.isShow = false;
			},
		},
	};
</script>
<style lang="less" scoped>
	.footer {
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #ffffff;
		width: 92%;
		padding: 10px 4%;
		color: #000000;
		z-index: 999999;

		.footer-mid {
			position: absolute;
			left: 18%;
			transform: translateX(-18%);
		}
	}

	.droptop {
		background-color: #ffffff;
		z-index: 999;
		position: fixed;
		bottom: 52px;
		left: 12%;
		border-radius: 2px;
		font-size: 15px;
		text-align: center;

		div {
			border-bottom: 1px #afafaf solid;
			padding: 10px;
		}

		div:last-child {
			border-bottom: none;
		}
	}
</style>