import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        user: false,
		query: [],
		page: 1,
		type:1,
    },
    mutations: {
        // 登录
        login(state, user) {
            state.user = user;
            localStorage.setItem("userInfo", user);
        },
        // 退出
        logout(state, user) {
            state.user = "";
            localStorage.setItem("userInfo", "");
        },
		setQuery(state,query){
			state.query = query;
		},
		setPage(state,page){
			state.page = page;
		},
		setQuery1(state,query){
			state.query = query;
		},
		setPage1(state,page){
			state.page = page;
		},
		setPage2(state,page){
			state.page = page;
		},
		setType(state,type) {
			state.type = type;
		}
    },
	getters: {
		getQuery(state){
			return state.query;
		},
		getPage(state){
			return state.page;
		},
		getQuery1(state){
			return state.query;
		},
		getPage1(state){
			return state.page;
		},
		getPage2(state){
			return state.page;
		},
		getType(state) {
			return state.type;
		}
	}
})