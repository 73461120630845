<template>
  <div>
    <div class="info">
      <div class="info-left">
        <div>客服电话：{{ webAttr }}</div>
        <div>客服QQ： {{ webQq }}</div>
        <div>咨询时间：{{ webSiteTime }}</div>
        <div>
          邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：{{ webEmail }}
        </div>
        <div>备&nbsp;&nbsp;案&nbsp;&nbsp;号：{{ webRecordnum }}</div>
      </div>
      <div class="info-right">
        <img :src="imageUrl(logo)" alt="" />
      </div>
    </div>
    <div class="banquan">版权所有 {{ webCopyright }}</div>
  </div>
</template>

<script>
import { footData } from "../../request/api";
export default {
  data() {
    return {
      webAttr: "",
      webCompanyAddress: "",
      webCopyright: "",
      webEmail: "",
      webQq: "",
      webRecordnum: "",
      webSiteTime: "",
      webintroduction: "",
      logo: "",
    };
  },

  created() {
    this.getFootData();
  },
  methods: {
    //获取数据
    getFootData() {
      footData({}).then((res) => {
        // console.log(res.data)
        this.webAttr = res.data.webAttr.value;
        this.webCompanyAddress = res.data.webCompanyAddress.value;
        this.webCopyright = res.data.webCopyright.value;
        this.webEmail = res.data.webEmail.value;
        this.webQq = res.data.webQq.value;
        this.webRecordnum = res.data.webRecordnum.value;
        this.webSiteTime = res.data.webSiteTime.value;
        this.webintroduction = res.data.webintroduction.value;
        this.logo = res.data.webLogo.value;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  padding: 0 3%;
  margin-top: 25px;
  font-size: 11px;
  color: #444444;
  display: flex;
  justify-content: space-between;
  .info-left {
    div {
      margin-bottom: 18px;
    }
  }
  .info-right {
    margin-top: 20px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.banquan {
  padding: 0 3%;
  font-size: 11px;
  color: #444444;
}
</style>