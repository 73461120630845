import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		redirect: '/index'
	},
	//��ҳ
	{
		path: '/index',
		name: 'Index',
		component: () => import('../pages/index/index.vue')
	},
	// �ҵ�
	{
		path: '/my',
		name: 'My',
		component: () => import('../pages/my/my.vue')
	},
	// ��ע
	{
		path: '/collection',
		name: 'Collection',
		component: () => import('../pages/collection/collection.vue')
	},
	// �ƹ�
	{
		path: '/promote',
		name: 'Promote',
		component: () => import('../pages/promote/promote.vue')
	},
	// �ҵĶ���
	{
		path: '/order',
		name: 'Order',
		component: () => import('../pages/order/order.vue')
	},
	// ���۶���
	{
		path: '/sales',
		name: 'Sales',
		component: () => import('../pages/sales/sales.vue')
	},
	// ��������
	{
		path: '/data',
		name: 'Data',
		component: () => import('../pages/data/data.vue')
	},
	// vip
	{
		path: '/vip',
		name: 'Vip',
		component: () => import('../pages/vip/vip.vue')
	},
	// ���ﳵ
	{
		path: '/cart',
		name: 'Cart',
		component: () => import('../pages/cart/cart.vue')
	},
	// ��¼
	{
		path: '/login',
		name: 'Login',
		component: () => import('../pages/login/login.vue')
	},
	// ��ֵ
	{
		path: '/recharge',
		name: 'Recharge',
		component: () => import('../pages/recharge/recharge.vue')
	},
	// ��Ȩ����
	{
		path: '/copyright',
		name: 'Copyright',
		component: () => import('../pages/copyright/copyright.vue')
	},
	// �ҵĻ�Ա
	{
		path: '/myVip',
		name: 'MyVip',
		component: () => import('../pages/myVip/myVip.vue')
	},
	// ������ϸ
	{
		path: '/withdrawal',
		name: 'Withdrawal',
		component: () => import('../pages/withdrawal/withdrawal.vue')
	},
	// �������
	{
		path: '/balance',
		name: 'Balance',
		component: () => import('../pages/balance/balance.vue')
	},
	// ������ҳ
	{
		path: '/author',
		name: 'Author',
		component: () => import('../pages/author/author.vue')
	},
	// �б�ҳ
	{
		path: '/list',
		name: 'List',
		component: () => import('../pages/list/list.vue'),
		meta: {
			keepAlive: true
		}
	},
	// ����
	{
		path: '/details',
		name: 'Details',
		component: () => import('../pages/details/details.vue')
	},
	// ����
	{
		path: '/search',
		name: 'Search',
		component: () => import('../pages/search/search.vue')
	},
	// ����Э��
	{
		path: '/clause',
		name: 'Clause',
		component: () => import('../pages/clause/clause.vue')
	},
	// ���ȫ��
	{
		path: '/authorAll',
		name: 'AuthorAll',
		component: () => import('../pages/authorAll/authorAll.vue')
	},
	// ���ȫ��
	{
		path: '/all',
		name: 'All',
		component: () => import('../pages/all/all.vue')
	},
	//招聘
	{
		path: '/recruit',
		component: 'Recruit',
		component: () => import('../pages/recruit/index.vue')
	},
	//招聘
	{
		path: '/recruitDetail',
		component: 'RecruitDetail',
		component: () => import('../pages/recruit/detail.vue')
	},
	//招聘
	{
		path: '/recruitRelease',
		component: 'RecruitRelease',
		component: () => import('../pages/recruit/release.vue')
	},
	//招聘
	{
		path: '/talk',
		component: 'talk',
		component: () => import('../pages/talk/index.vue')
	},
]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
		// return { x: 0, y: 0 }
	}
})
export default router