import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//引入样式重置
import 'reset-css'

//引入element ui 样式
import 'element-ui/lib/theme-chalk/index.css';
import "./element"

import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)

import "./static/iconfont.css";

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import * as custom from './until/until'

import lib from  "./lib"

Vue.use(lib)
import imageUrl from  "./until/newUrl.js"

Vue.prototype.imageUrl = imageUrl;

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
