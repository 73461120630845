<template>
  <div id="app">
	  <div v-if="keepAlive">
		  <keep-alive>
		  		<router-view/>
		  </keep-alive>
	  </div>
	  <div v-if="!keepAlive">
		  <router-view/>
	  </div>
  </div>
</template>
<script type="text/javascript">
export default {
	created() {
		if(window.innerWidth > 1000) {
			var url = document.URL.replace(window.location.host,'www.yituhuitu.com');
			url = url.replace('https','http');
			url = url.replace('www.yituhuitu.com/details','www.yituhuitu.com/openAreaDetail');
			window.location.href = url;
		}else{
			var that = this;
			that.$router.onReady(() => {
			    if (that.$route.matched.length <= 0){
			    	window.location.href = "/";
			    	return false;
			    }
			})
		}
	},
	watch: {
		'$route': function(e){
			this.keepAlive = e.meta.keepAlive || false
		}
	},
	data(){
		return{
			keepAlive: false
		}
	},
}
</script>
<style lang="scss">
@import "./static/iconfont.css";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
html{
  
    height: 100%;
}

body{
  height: 100%;
}

#app{
  //  height: 100%;
   background: #f6f6f6;
}

</style>
